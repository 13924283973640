import styled from "@emotion/styled"
import slugify from "@sindresorhus/slugify"
import { Link } from "gatsby"
import * as React from "react"
import tw from "twin.macro"
import Icon from "../components/Icon"
import { Layout } from "../components/Layout"

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  & > * + * {
    margin-top: 1rem;
  }
  font-size: 1.2rem;
  padding: 1rem 0;
`

const TypeHeader = styled.h1`
  width: 100%;
  text-align: center;
  text-transform: capitalize;
  background-color: rgba(33, 33, 33, 0.6);
  color: white;
`

const IndexPage = props => {
  const {
    pageContext: { pages },
    location,
  } = props
  return (
    <Layout location={location}>
      <Wrapper>
        <Link to="/">⨞ Back</Link>
        {pages.map((page, i) => {
          const previousPage = pages[i - 1]
          return (
            <>
              {previousPage?._type !== page?._type && (
                <TypeHeader>
                  {page._type.split(/(?=[A-Z])/).join(" ")}
                </TypeHeader>
              )}
              <Link
                to={
                  page.url
                    ? page.url
                    : `/${slugify(page._type)}/${page._id.replace(
                        "drafts.",
                        ""
                      )}`
                }
              >
                <div css={tw`flex flex-row space-x-1`}>
                  <div>{page.name}...</div>
                  {page._id.includes("drafts") && (
                    <Icon size={0.8} icon="mdiPen" />
                  )}
                </div>
              </Link>
            </>
          )
        })}
      </Wrapper>
    </Layout>
  )
}

export default IndexPage
